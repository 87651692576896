<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
        mdi-file-document
      </v-icon>
    </template>

    <span>Certificaciones</span>

    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title class="text-h6 grey lighten-2">
            <span class="headline">Certificaciones</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="pl-2 pr-2">
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <v-date-picker v-model="expire" :rules="rules" :disabled="saveChanges"></v-date-picker>
                </v-col>
                <v-col lg="8" md="4" sm="12">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                          v-model="certification"
                          :items="items"
                          :disabled="saveChanges"
                          :rules="rules"
                          item-value="id"
                          item-text="name"
                          outlined
                          label="Certificaciones"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                          :rules="rules"
                          show-size
                          small-chips
                          :disabled="saveChanges"
                          label="Documento"
                          truncate-length="50"
                          v-model="file"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <v-btn :disabled="!valid || saveChanges" color="primary" :loading="saveChanges"
                             @click="saveCertifications">
                        Guardar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1" class="font-weight-bold"></v-col>
                    <v-col cols="3" class="font-weight-bold">Certificaci&oacute;n</v-col>
                    <v-col cols="2" class="font-weight-bold">Fecha de expiraci&oacute;n</v-col>
                    <v-col cols="2" class="font-weight-bold">Fecha de subida</v-col>
                    <v-col cols="2" class="font-weight-bold">Subido por</v-col>
                    <v-col cols="2" class="font-weight-bold">Acciones</v-col>
                  </v-row>
                  <v-row v-for="itemImage in certificationsEntity" :key="itemImage.id">
                    <v-col cols="1">
                      <v-avatar size="36px" v-if="itemImage.image_certification">
                        <v-img :src="itemImage.image_certification" :lazy-src="itemImage.image_certification"/>
                      </v-avatar>
                      <v-icon v-else size="36px">mdi-certificate-outline</v-icon>
                    </v-col>
                    <v-col cols="3" class="d-flex">
                      <label>{{ itemImage.name }}</label>
                    </v-col>
                    <v-col cols="2">{{ formatDate(itemImage.expire) }}</v-col>
                    <v-col cols="2">{{ formatDate(itemImage.created_at) }}</v-col>
                    <v-col cols="2">{{itemImage | changeCreated}}</v-col>
                    <v-col cols="2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small class="mr-2"
                                  v-on:click="goToLink(itemImage.pivot.url)">
                            mdi-file-document
                          </v-icon>
                        </template>
                        <span>Descargar</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small class="mr-2" v-on:click="deleteItem(itemImage)">
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="saveChanges" text @click="close">
              Cancelar
            </v-btn>
            <v-btn color="primary" :loading="saveChanges"
                   @click="close">
              Salir
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </v-tooltip>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Certifications",
  props: ["entity", "url", "deleteUrl"],
  data() {
    return {
      dialog: false,
      keywords: "",
      certifications: [],
      documents: [],
      certificationsEntity: [],
      file: null,
      certification: null,
      expire: new Date(),
      saveChanges: false,
      snackBar: false,
      snackText: "",
      rules: [
        (v) => !!v || 'El campo es requerido'
      ],
      valid: false,
    }
  },
  filters: {
    changeCreated(value){
      if(value.doctor_certifications.length > 0){
        const user = value.doctor_certifications[0].user;
        return user?user.email:"";
      }
      return "";
    }
  },
  computed: {
    items() {
      return this.certifications.filter(e => !this.certificationsEntity.find(f => f.id === e.id));
    },
    defaultDate() {
      const today = new Date();
      const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      const month = today.getMonth() < 9 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      return `${today.getFullYear()}-${month}-${day}`;
    }
  },

  methods: {
    deleteItem(item) {
      axios.post(this.deleteUrl, {
            id: this.entity.id,
            certification: item.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
          }
      ).then(resp => {
        if (resp.status === 200) {
          this.certificationsEntity = this.certificationsEntity.filter(e => e.id != item.id);
          this.snackText = "Certificación elminada con éxito";
        }
        else{
          this.snackText = 'Ha ocurrido un error';
        }
      }).catch(() => {
        this.snackText = 'Ha ocurrido un error';
      }).finally(()=>{
        this.snackBar = true;
      })
    },
    loadDoctorCertifications() {
      axios.get(`${process.env.VUE_APP_BHC_BASE_URL}/doctors/${this.entity.id}`).then(resp => {
        this.certificationsEntity = resp.data.value.certifications;
      })
    },
    getCertifications() {
      axios.get(`${process.env.VUE_APP_BHC_BASE_URL}/view-list/certifications`).then(resp => {
        this.certifications = resp.data.value;
      })
    },
    formatDate(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY");
    },
    openDialog() {
      this.certificationsEntity = this.entity.certifications.doctor_certifications ?? [];
      this.expire = this.defaultDate;
      this.getCertifications();
      this.loadDoctorCertifications();
      this.certificationsEntity = this.entity.certifications ?? [];
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.dialog = true;
      this.valid = false;
    },
    goToLink(link) {
      window.open(link, '_blank');
    },
    close() {
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.dialog = false;
      this.valid = false;
    },

    reset() {
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.valid = false;
      this.dialog = false;
      this.$emit('reload');
    },

    saveCertifications() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.saveChanges = true;
        const formData = new FormData();
        formData.append("id", this.entity.id);
        formData.append("certification", this.certification);
        formData.append("file", this.file);
        formData.append("expire", this.expire);
        axios.post(this.url, formData,
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
              }
            }
        ).then(resp => {
          if (resp.data.success) {
            this.file = null;
            this.certification = null;
            this.expire = this.defaultDate;
            this.valid = false;
            this.saveChanges = false;
            this.snackText = 'Certificación insertada con éxito';
            this.loadDoctorCertifications();
          }
          if(resp.status === 400) {
            this.snackText = resp.data.message;
          }
        }).catch(err => console.log(err)).finally(() => {
          this.saveChanges = false;
          this.snackBar = true;
        });
      }
    }
  }
}
</script>

<style>
.a-custom {
  margin-right: 1.6rem;
  text-decoration: none;
}

.i-custom {
  color: #fff !important;
  font-size: 20px !important;
}
</style>
