import axios from "axios";
import store from "@/store/store.js";


export const paginationServices = (url, pendings = false)=>{
    const urlServices = url.startsWith(store.getters.getBaseURL)?url: `${store.getters.getBaseURL}/${url}`;
    return axios.get(urlServices, {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            params: {
                pendings: pendings
            }
          });
}