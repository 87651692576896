import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

    constructor() {
        this.#baseUrl = store.getters.getBaseURL;
    }

    async reload() {
        this.header = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        };
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/certifications");
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewList() {
        try {
            let response = await axios.get(this.#baseUrl + "/view-list/certifications");
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/certifications/${id}`);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async uploadImage(id, imageFile) {
        if (imageFile) {
            try {
                await this.reload();
                let formData = new FormData();
                formData.append("id", id);
                formData.append("file", imageFile);
                return axios.post(
                    this.#baseUrl + "/upload-image/certifications",
                    formData,
                    {
                        headers: this.header
                    }
                );
            } catch (error) {
                return this.handleError(error);
            }
        }
    }

    deleteImage(data) {
        this.reload();
        return axios.post(
            this.#baseUrl + "/delete-image/certifications",
            data,
            {
                headers: this.header
            }
        )
    }

    addRecord(formData) {
        this.reload();
        return axios.post(this.#baseUrl + "/certifications", formData, {
          headers: this.header
        });
    }

    // Pushes posts to the server when called.
    updateRecord(id, formData) {
        this.reload();
        return axios.put(`${this.#baseUrl}/certifications/${id}`, formData, {
          headers: this.header
        });
    }

    deleteRecord(id) {
        this.reload();
        return axios.delete(`${this.#baseUrl}/certifications/${id}`, {
          headers: this.header
        });    
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        return valueError;
    }
}